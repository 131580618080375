import { useContext, useEffect, useState } from 'react'
import { LoginContext } from '@/context/login'
import {
    faCheckCircle, faDollarSign, faEnvelope,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '../ui/Button'
import SubNav from '../menu/SubNav'
import AuthService from '@/services/auth'
import Hooks from '@/components/tool/Hooks'
import { AuthContext, AuthContextProvider } from '@/context/auth'
import Upload from '../ui/Upload'
import { useForm } from 'react-hook-form'

export default function Investment(props) {
    const [loginState, loginDispatch] = useContext(LoginContext)
    const [, authDispatch] = useContext(AuthContext)
    const [ready, setReady] = useState(false)
    const width = Hooks()
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, errors } = useForm()
    const [investmentValue, setInvestmentValue] = useState("")
    const [proofValue, setProofValue] = useState("")
    const [proofFile, setProofFile] = useState(null)
    const [disable, setDisable] = useState(true)


    useEffect(async () => { }, [ready])

    useEffect(async () => {
        if (loginState.auth.session_id === '' || loginState.auth.account_type === '') {
            AuthService.Me()
                .then((data) => {
                    authDispatch({
                        type: 'SET_AUTH',
                        payload: {
                            user: {
                                email: data.email,
                                firstname: data.firstname,
                                lastname: data.lastname,
                                profile_pic: data.profile_pic,
                            },
                            iam: data.iam,
                            authenticated: true,
                        },
                    })

                    loginDispatch({
                        type: 'SET_LOGIN',
                        payload: {
                            auth: {
                                session_id: data.session,
                                account_type: data.session_type,
                                wealth: data.steps.wealth,
                                investment: data.steps.investment,
                                kyc: data.steps.kyc,
                                video: data.steps.video,
                            },
                        },
                    })

                    setReady(true)
                })
                .catch((err) => {
                    authDispatch({
                        type: 'SET_AUTH',
                        payload: {
                            user: {
                                email: '',
                                firstname: '',
                                lastname: '',
                                profile_pic: '',
                            },
                            iam: [],
                            authenticated: false,
                        },
                    })
                    setReady(true)
                })
            return () => { }
        } else {
            setReady(true)
        }
        return () => { }
    }, [])

    const onSubmit = async (data) => {
        setError("")
        setLoading(true)

        if (investmentValue === "CRYPTO" && proofValue === "YES" && proofFile === null) {
            setError("Missing file")
            setLoading(false)
            return
        }

        let bodyFormData = new FormData()
        bodyFormData.append('investment', investmentValue)
        bodyFormData.append('proof_transferred', proofValue)

        if (proofValue === "YES") {
            bodyFormData.append('file', proofFile)
        }

        await AuthService.UpdateInvestment(bodyFormData)
            .then((data) => {
                setLoading(false)
                loginDispatch({
                    type: 'SET_LOGIN',
                    payload: {
                        auth: {
                            session_id: loginState.auth.session_id,
                            account_type: loginState.auth.account_type,
                            wealth: loginState.auth.wealth,
                            investment: true,
                            kyc: loginState.auth.kyc,
                            video: loginState.auth.video,
                        },
                    },
                })
                props.onNext()
            })
            .catch((err) => {
                setLoading(false)
                setError(err.message)
            })
        return () => { }
    }

    const formSchema = {}

    function UpdateInvestmentValue(value) {
        if (investmentValue === value) {
            setInvestmentValue("")
            setDisable(true)
        } else {
            if (value === "FIAT") {
                setDisable(false)
            } else {
                setDisable(true)
            }
            setInvestmentValue(value)
        }
    }

    function UpdateProofValue(value) {
        if (proofValue === value) {
            setProofValue("")
            setDisable(true)
        } else {
            setDisable(false)
            setProofValue(value)
        }
    }

    async function goBack() {
        setLoading(true)
        let bodyFormData = new FormData()
        bodyFormData.append('step', 'WEALTH')

        await AuthService.Undo(bodyFormData)
            .then((data) => {
                setLoading(false)
                loginDispatch({
                    type: 'SET_LOGIN',
                    payload: {
                        auth: {
                            session_id: loginState.auth.session_id,
                            account_type: loginState.auth.account_type,
                            wealth: false,
                            investment: loginState.auth.investment,
                            kyc: loginState.auth.kyc,
                            video: loginState.auth.video,
                        },
                    },
                })
                props.onBack()
            })
            .catch((err) => {
                setLoading(false)
                setError(err.message)
            })
        return () => { }
    }

    return (
        <div>
            <div>
                <SubNav></SubNav>
                <div className='max-w-7xl mx-auto relative'>
                    <div className='dashboard-container'>
                        <div className='bg-white rounded-xl p-6 shadow'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <label
                                        className="block text-sm font-medium text-gray-700 mb-2">
                                        How are you planning to fund your investment ?
                                    </label>
                                    <div className='flex'>
                                        <div className="flex mr-5">
                                            <input className="checkbox mr-3 ml-2 mt-1"
                                                type="checkbox"
                                                checked={investmentValue === "FIAT"}
                                                onChange={() => UpdateInvestmentValue("FIAT")} />
                                            <p className="block text-sm mb-3 font-medium text-primary-700">FIAT</p>
                                        </div>
                                        <div className="flex mr-5">
                                            <input className="checkbox mr-3 ml-2 mt-1"
                                                type="checkbox"
                                                checked={investmentValue === "CRYPTO"}
                                                onChange={() => UpdateInvestmentValue("CRYPTO")} />
                                            <p className="block text-sm mb-3 font-medium text-primary-700">CRYPTO</p>
                                        </div>
                                    </div>
                                </div>
                                {investmentValue === "CRYPTO" ?
                                    <>
                                        <div>
                                            <label
                                                className="block text-sm font-medium text-gray-700 mb-2">
                                                Have you already transferred a proof of wallet access ?
                                            </label>
                                            <div className='flex'>
                                                <div className="flex mr-5">
                                                    <input className="checkbox mr-3 ml-2 mt-1"
                                                        type="checkbox"
                                                        checked={proofValue === "YES"}
                                                        onChange={() => UpdateProofValue("YES")} />
                                                    <p className="block text-sm mb-3 font-medium text-primary-700">YES</p>
                                                </div>
                                                <div className="flex mr-5">
                                                    <input className="checkbox mr-3 ml-2 mt-1"
                                                        type="checkbox"
                                                        checked={proofValue === "NO"}
                                                        onChange={() => UpdateProofValue("NO")} />
                                                    <p className="block text-sm mb-3 font-medium text-primary-700">NO</p>
                                                </div>
                                            </div>
                                        </div>
                                        {proofValue === "YES" ? <>
                                            <label
                                                className="block text-sm font-medium text-gray-700 mb-5">
                                                Please upload your transaction e-mail confirmation sent to you by your provider, or alternatively a screenshot of your wallet interface, on which ideally the following details are visible, depending on your provider:<br></br>
                                                - Blockchain Transaction Hash/ID of the Transaction to Sygnum<br></br>
                                                - Provider name applicable (logo, URL)<br></br>
                                                - Client name, email or technical ID<br></br>
                                                Please don&apos;t upload evidence from a public Block Explorer such as Blockchain.com, Etherscan.io and the like.
                                            </label>
                                            <Upload
                                                loading={loading}
                                                acceptedFileTypes={[
                                                    'image/jpeg',
                                                    'image/png',
                                                    'image/jpg',
                                                ]}
                                                setFile={setProofFile}></Upload>
                                        </> : <>
                                            {proofValue === "NO" ? <> <label
                                                className="block text-sm font-medium text-gray-700 mb-2">
                                                Upon transfer, kindly email the screenshot to <a className='font-bold' href="mailto:aliya.dasgupta@sygnum.com">aliya.dasgupta@sygnum.com</a>, showing your transaction e-mail confirmation sent to you by your provider, or alternatively a screenshot of your wallet interface, on which ideally the following details are visible, depending on your provider:<br></br>
                                                - Blockchain Transaction Hash/ID of the Transaction to Sygnum<br></br>
                                                - Provider name applicable (logo, URL)<br></br>
                                                - Client name, email or technical ID<br></br>
                                                Please don&apos;t upload evidence from a public Block Explorer such as Blockchain.com, Etherscan.io and the like.
                                            </label></> : null}
                                        </>
                                        }
                                    </>
                                    : null}

                                {error !== "" ? <p className="mt-2 text-sm text-red-600" id="email-error">
                                    {error}
                                </p> : null}

                                <div className='flex justify-between mt-10'>
                                    <div>
                                        <Button
                                            type='submit'
                                            full
                                            loading={loading}
                                            label='Back'
                                            onClick={() => goBack()}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            disabled={disable}
                                            type='submit'
                                            full
                                            loading={loading}
                                            label='Next'
                                            onClick={() => onSubmit()}
                                        />
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
