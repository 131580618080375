import { useContext, useEffect, useState } from 'react'
import { LoginContext } from '@/context/login'
import {
	faBuilding,
	faCheckCircle, faDollarSign, faEnvelope, faText, faUser,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Textarea from '@/components/ui/Textarea'
import Button from '../ui/Button'
import SubNav from '../menu/SubNav'
import AuthService from '@/services/auth'
import Hooks from '@/components/tool/Hooks'
import { AuthContext, AuthContextProvider } from '@/context/auth'
import Input from '@/components/ui/Input'
import { useForm } from 'react-hook-form'

export default function Wealth(props) {
	const [loginState, loginDispatch] = useContext(LoginContext)
	const [, authDispatch] = useContext(AuthContext)
	const [ready, setReady] = useState(false)
	const width = Hooks()
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const { register, handleSubmit, errors } = useForm()
	const [wealthValue, setWealthValue] = useState("")
	const [incomeValue, setIncomeValue] = useState("")
	const [sourceValue, setSourceValue] = useState("")
	const [descriptionValue, setDescriptionValue] = useState("")
	const [sourceOtherValue, setSourceOtherValue] = useState("")
	const [employerValue, setEmployerValue] = useState("")
	const [disable, setDisable] = useState(true)


	useEffect(async () => { }, [ready])

	useEffect(async () => {
		if (loginState.auth.session_id === '' || loginState.auth.account_type === '') {
			AuthService.Me()
				.then((data) => {
					authDispatch({
						type: 'SET_AUTH',
						payload: {
							user: {
								email: data.email,
								firstname: data.firstname,
								lastname: data.lastname,
								profile_pic: data.profile_pic,
							},
							iam: data.iam,
							authenticated: true,
						},
					})

					loginDispatch({
						type: 'SET_LOGIN',
						payload: {
							auth: {
								session_id: data.session,
								account_type: data.session_type,
								wealth: data.steps.wealth,
								investment: data.steps.investment,
								kyc: data.steps.kyc,
								video: data.steps.video,
							},
						},
					})

					setReady(true)
				})
				.catch((err) => {
					authDispatch({
						type: 'SET_AUTH',
						payload: {
							user: {
								email: '',
								firstname: '',
								lastname: '',
								profile_pic: '',
							},
							iam: [],
							authenticated: false,
						},
					})
					setReady(true)
				})
			return () => { }
		} else {
			setReady(true)
		}
		return () => { }
	}, [])

	const onSubmit = async (data) => {
		setError("")
		setLoading(true)

		if (wealthValue === "") {
			setError("Missing current total wealth")
			return
		}

		if (sourceValue === "") {
			setError("Source of wealth")
			return
		}

		if (sourceValue === "Other" && sourceOtherValue === "") {
			setError("Missing custom source of wealth")
			return
		}

		if (incomeValue === "") {
			setError("Missing annual income")
			return
		}

		if (employerValue === "") {
			setError("Missing employer")
			return
		}

		if (descriptionValue === "") {
			setError("Missing description")
			return
		}


		const body = {
			total_of_wealth: wealthValue,
			source_of_wealth: sourceValue,
			annual_income: incomeValue,
			employer: employerValue,
			description: descriptionValue,
		}

		if (sourceValue === "Other") {
			body.source_of_wealth = sourceOtherValue
		}

		await AuthService.UpdateWealth(body)
			.then((data) => {
				setLoading(false)
				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							session_id: loginState.auth.session_id,
							account_type: loginState.auth.account_type,
							wealth: true,
							investment: loginState.auth.investment,
							kyc: loginState.auth.kyc,
							video: loginState.auth.video,
						},
					},
				})
				props.onNext()
			})
			.catch((err) => {
				setLoading(false)
				setError(err.message)
			})
		return () => { }
	}

	const formSchema = {}


	function UpdateWealthValue(value) {
		if (wealthValue === value) {
			setWealthValue("")
			setDisable(true)
		} else {
			setWealthValue(value)
			if (value !== "" && incomeValue !== "" && sourceValue !== "" && employerValue !== "" && descriptionValue !== "") {
				setDisable(false)
			}
		}
	}

	function UpdateIncomeValue(value) {
		if (incomeValue === value) {
			setIncomeValue("")
			setDisable(true)
		} else {
			setIncomeValue(value)
			if (wealthValue !== "" && value !== "" && sourceValue !== "" && employerValue !== "" && descriptionValue !== "") {
				setDisable(false)
			}
		}
	}

	function UpdateSourceValue(value) {
		if (sourceValue === value) {
			setSourceValue("")
			setDisable(true)
		} else {
			setSourceValue(value)
			if (wealthValue !== "" && incomeValue !== "" && value !== "" && employerValue !== "" && descriptionValue !== "") {
				setDisable(false)
			}
		}
	}

	function UpdateEmployer(value) {
		if (value === "") {
			setEmployerValue("")
			setDisable(true)
		} else {
			setEmployerValue(value)
			if (wealthValue !== "" && incomeValue !== "" && sourceValue !== "" && value !== "" && descriptionValue !== "") {
				setDisable(false)
			}
		}
	}

	function UpdateDescription(value) {
		if (value === "") {
			setDescriptionValue("")
			setDisable(true)
		} else {
			setDescriptionValue(value)
			if (wealthValue !== "" && incomeValue !== "" && sourceValue !== "" && value !== "" && employerValue !== "") {
				setDisable(false)
			}
		}
	}

	async function goBack() {
		setLoading(true)
		let bodyFormData = new FormData()
		bodyFormData.append('step', 'VIDEO')

		await AuthService.Undo(bodyFormData)
			.then((data) => {
				setLoading(false)
				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							session_id: loginState.auth.session_id,
							account_type: loginState.auth.account_type,
							wealth: loginState.auth.wealth,
							investment: loginState.auth.investment,
							kyc: loginState.auth.kyc,
							video: false,
						},
					},
				})
				props.onBack()
			})
			.catch((err) => {
				setLoading(false)
				setError(err.message)
			})
		return () => { }
	}

	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className='max-w-7xl mx-auto relative'>
					<div className='dashboard-container'>
						<div className='bg-white rounded-xl p-6 shadow'>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div>
									<label
										className="block text-sm font-medium text-gray-700 mb-2">
										Please indicate your current total wealth :
									</label>
									<div className='flex'>
										<div className="flex mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={wealthValue === "<500'000"}
												onChange={() => UpdateWealthValue("<500'000")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">&#8804; 500&apos;000</p>
										</div>
										<div className="flex ml-5 mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={wealthValue === "500'001-2'000'000"}
												onChange={() => UpdateWealthValue("500'001-2'000'000")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">500&apos;001-2&apos;000&apos;000</p>
										</div>
										<div className="flex ml-5 mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={wealthValue === "2'000'001-5'000'000"}
												onChange={() => UpdateWealthValue("2'000'001-5'000'000")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">2&apos;000&apos;001-5&apos;000&apos;000</p>
										</div>
										<div className="flex ml-5 mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={wealthValue === "5'000'001-10'000'000"}
												onChange={() => UpdateWealthValue("5'000'001-10'000'000")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">5&apos;000&apos;001-10&apos;000&apos;000</p>
										</div>
									</div>
									<div className='flex'>
										<div className="flex mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={wealthValue === "10'000'001-25'000'000"}
												onChange={() => UpdateWealthValue("10'000'001-25'000'000")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">10&apos;000&apos;001-25&apos;000&apos;000</p>
										</div>
										<div className="flex ml-5 mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={wealthValue === ">25'000'000"}
												onChange={() => UpdateWealthValue(">25'000'000")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">&#62; 25&apos;000&apos;000</p>
										</div>
										<div className="flex ml-5 mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={wealthValue === ">50'000'000"}
												onChange={() => UpdateWealthValue(">50'000'000")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">&#62; 50&apos;000&apos;000</p>
										</div>
									</div>
								</div>
								<div className='mt-5'>
									<label
										className="block text-sm font-medium text-gray-700 mb-2">
										Please provide an image of source of wealth :
									</label>
									<div className="flex mr-5">
										<div className="flex mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={sourceValue === "Self-employment or Salaried income"}
												onChange={() => UpdateSourceValue("Self-employment or Salaried income")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">Self-employment or Salaried income</p>
										</div>
										<div className="flex ml-5 mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={sourceValue === "Inherance/gift"}
												onChange={() => UpdateSourceValue("Inherance/gift")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">Inherance/gift</p>
										</div>
										<div className="flex ml-5 mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={sourceValue === "Sale of company"}
												onChange={() => UpdateSourceValue("Sale of company")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">Sale of company</p>
										</div>
										<div className="flex ml-5 mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={sourceValue === "Sale of Real Estate"}
												onChange={() => UpdateSourceValue("Sale of Real Estate")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">Sale of Real Estate</p>
										</div>
									</div>
									<div className="flex mr-5">
										<div className="flex mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={sourceValue === "Investment earnings"}
												onChange={() => UpdateSourceValue("Investment earnings")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">Investment earnings</p>
										</div>
										<div className="flex ml-5 mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={sourceValue === "Digital asset earnings"}
												onChange={() => UpdateSourceValue("Digital asset earnings")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">Digital asset earnings</p>
										</div>
										<div className="flex ml-5 mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={sourceValue === "Pension Fund"}
												onChange={() => UpdateSourceValue("Pension Fund")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">Pension Fund</p>
										</div>
										<div className="flex ml-5 mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={sourceValue === "Other"}
												onChange={() => UpdateSourceValue("Other")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">Other (please specify)</p>
										</div>
									</div>
									{sourceValue === "Other" ?
										<Input
											icon={faDollarSign}
											label=''
											setValue={(e) => setSourceOtherValue(e)}
											placeholder='other'
											name='other'
										/> : null}
								</div>
								<div className='mt-5'>
									<label
										className="block text-sm font-medium text-gray-700 mb-2">
										Annual Income (in CHF)
									</label>
									<div className='flex'>
										<div className="flex mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={incomeValue === "<100'000"}
												onChange={() => UpdateIncomeValue("<100'000")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">&#8804; 100&apos;000</p>
										</div>
										<div className="flex ml-5 mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={incomeValue === "100'001-250'000"}
												onChange={() => UpdateIncomeValue("100'001-250'000")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">100&apos;001-250&apos;000</p>
										</div>
										<div className="flex ml-5 mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={incomeValue === "250'001-500'000"}
												onChange={() => UpdateIncomeValue("250'001-500'000")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">250&apos;001-500&apos;000</p>
										</div>
										<div className="flex ml-5 mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={incomeValue === "500'001-1'000'000"}
												onChange={() => UpdateIncomeValue("500'001-1'000'000")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">500&apos;001-1&apos;000&apos;000</p>
										</div>
										<div className="flex ml-5 mr-5">
											<input className="checkbox mr-3 ml-2 mt-1"
												type="checkbox"
												checked={incomeValue === ">1'000'000"}
												onChange={() => UpdateIncomeValue(">1'000'000")} />
											<p className="block text-sm mb-3 font-medium text-primary-700">&#62; 1&apos;000&apos;000</p>
										</div>
									</div>
								</div>
								<div className='mb-5 mt-5'>
									<Input
										setValue={(e) => UpdateEmployer(e)}
										icon={faUser}
										label='Name of current or most recent employer'
										placeholder='Synaps'
										name='email'
									/>
								</div>
								<div className='mb-5 mt-5'>
									<label
										className="block text-sm font-medium text-gray-700 mb-2">
										Please provide a brief description of how you built your wealth
									</label>
									<Textarea
										setValue={(e) => UpdateDescription(e)}
										icon={faBuilding}
										label='Please provide a brief description of how you built your wealth'
										placeholder='Business of ...'
										name='email'
									/>
								</div>
								{error !== "" ? <p className="mt-2 text-sm text-red-600" id="email-error">
									{error}
								</p> : null}
								<div className='flex justify-between mt-10'>
									<div>
										<Button
											type='submit'
											full
											loading={loading}
											label='Back'
											onClick={() => goBack()}
										/>
									</div>
									<div>
										<Button
											disabled={disable}
											type='submit'
											full
											loading={loading}
											label='Next'
											onClick={() => onSubmit()}
										/>
									</div>
								</div>

							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
