import { useContext, useEffect, useState } from 'react'
import { LoginContext } from '@/context/login'
import {
    faCheckCircle, faDollarSign, faEnvelope, faPassport,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '../ui/Button'
import SubNav from '../menu/SubNav'
import AuthService from '@/services/auth'
import Hooks from '@/components/tool/Hooks'
import { AuthContext, AuthContextProvider } from '@/context/auth'
import Upload from '../ui/Upload'
import { useForm } from 'react-hook-form'

export default function Video(props) {
    const [loginState, loginDispatch] = useContext(LoginContext)
    const [, authDispatch] = useContext(AuthContext)
    const [ready, setReady] = useState(false)
    const width = Hooks()
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, errors } = useForm()
    const [disable, setDisable] = useState(true)


    useEffect(async () => { }, [ready])

    useEffect(async () => {
        if (loginState.auth.session_id === '' || loginState.auth.account_type === '') {
            AuthService.Me()
                .then((data) => {
                    authDispatch({
                        type: 'SET_AUTH',
                        payload: {
                            user: {
                                email: data.email,
                                firstname: data.firstname,
                                lastname: data.lastname,
                                profile_pic: data.profile_pic,
                            },
                            iam: data.iam,
                            authenticated: true,
                        },
                    })

                    loginDispatch({
                        type: 'SET_LOGIN',
                        payload: {
                            auth: {
                                session_id: data.session,
                                account_type: data.session_type,
                                wealth: data.steps.wealth,
                                investment: data.steps.investment,
                                kyc: data.steps.kyc,
                                video: data.steps.video,
                            },
                        },
                    })

                    setReady(true)
                })
                .catch((err) => {
                    authDispatch({
                        type: 'SET_AUTH',
                        payload: {
                            user: {
                                email: '',
                                firstname: '',
                                lastname: '',
                                profile_pic: '',
                            },
                            iam: [],
                            authenticated: false,
                        },
                    })
                    setReady(true)
                })
            return () => { }
        } else {
            setReady(true)
        }
        return () => { }
    }, [])

    const onSubmit = async (data) => {
        setError("")
        setLoading(true)

        let body = {}

        await AuthService.UpdateVideo(body)
            .then((data) => {
                setLoading(false)
                loginDispatch({
                    type: 'SET_LOGIN',
                    payload: {
                        auth: {
                            session_id: loginState.auth.session_id,
                            account_type: loginState.auth.account_type,
                            wealth: loginState.auth.wealth,
                            investment: loginState.auth.investment,
                            kyc: loginState.auth.kyc,
                            video: true,
                        },
                    },
                })
                props.onNext()
            })
            .catch((err) => {
                setLoading(false)
                setError(err.message)
            })
        return () => { }

    }

    const formSchema = {}


    return (
        <div>
            <div>
                <SubNav></SubNav>
                <div className='max-w-7xl mx-auto relative'>
                    <div className='dashboard-container'>
                        <div className='bg-white rounded-xl p-6 shadow'>
                            <form className='mx-auto text-center mt-5' onSubmit={handleSubmit(onSubmit)}>
                                <p className="mt-1 mb-3">This process will open in a new window.<br></br>Please complete the identification process and return to this page to finish the KYC questionnaire </p>
                                <a type="submit" onClick={() => setDisable(false)} rel="noreferrer" href='https://go.online-ident.ch/sygnumvideo/userdata' target="_blank"
                                    className="mx-auto my-2 mx-auto mt-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-400 focus:outline-none focus-primary-btn active:bg-primary-700 transition duration-150 ease-in-out">Continue
                                </a>

                                {error !== "" ? <p className="mt-2 text-sm text-red-600" id="email-error">
                                    {error}
                                </p> : null}

                                <div className='flex justify-between mt-10'>
                                    <div></div>
                                    <div>
                                        <Button
                                            disabled={disable}
                                            type='submit'
                                            full
                                            loading={loading}
                                            label='Next'
                                            onClick={() => onSubmit()}
                                        />
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
