import StepItem from "./StepItem";

export default function WorkflowSteps(props) {
	return (
		<>
			<nav aria-label="Progress">
				<ol className="bg-primary-500 text-white-100 border-t border-gray-200 divide-y divide-gray-300 md:flex md:divide-y-0">
					<StepItem
						label="Video"
						number="1"
						current={
							props.current === "VIDEO"
						}
						done={
							props.video
						}
						last={false}
					></StepItem>{' '}
					<StepItem
						label="Wealth"
						number="2"
						current={
							props.current === "WEALTH"
						}
						done={
							props.wealth
						}
						last={false}
					></StepItem>{' '}
					<StepItem
						label="Investment"
						number="3"
						current={
							props.current === "INVESTMENT"
						}
						last={false}
						done={
							props.investment
						}></StepItem>{' '}
					<StepItem
						label="KYC"
						number="4"
						last={true}
						current={
							props.current === "KYC"
						}
						done={
							props.kyc
						}></StepItem>{' '}
				</ol>
			</nav>
		</>
	)
}