import { LoginContext } from '@/context/login'
import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import InitSignup from '@/components/auth/InitSignup'

export default function Signup() {
	const [loginState] = useContext(LoginContext)
	const router = useHistory()
	const [, loginDispatch] = useContext(LoginContext)

	useEffect(async () => {
		loginDispatch({
			type: 'SET_LOGIN',
			payload: {
				currentComponent: <InitSignup></InitSignup>,
			},
		})
	}, [])

	return (
		<>
			<div className='min-h-screen px-8 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
				<div className='mx-auto mb-5'>
					<img
						className='rounded-xl'
						src='/img/logo/logo.png'></img>
				</div>
				<div>
					<div className='sm:mx-auto sm:w-full sm:max-w-sm bg-white rounded-xl p-4 shadow'>
						{loginState.currentComponent}
					</div>
				</div>
				<div className="mx-auto mt-5">
					<a onClick={() => router.push('/login')} className="text-primary-500 font-bold hover:underline cursor-pointer">Login for the KYC process</a>
				</div>
			</div>
		</>
	)
}
