import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import LoginPage from '@/pages/login'
import SignupPage from '@/pages/signup'
import NotFoundPage from '@/pages/not-found'
import Dashboard from '@/pages/dashboard'
import { ToastContainer } from 'react-toastify'
import { AuthContext, AuthContextProvider } from '@/context/auth'
import { LoginContext, LoginContextProvider } from '@/context/login'
import { useEffect, useState, useContext } from 'react'
import AuthService from '@/services/auth'

function AuthCore(props) {
	const [, authDispatch] = useContext(AuthContext)
	const [, loginDispatch] = useContext(LoginContext)
	const [ready, setReady] = useState(false)

	useEffect(() => {
		AuthService.Me()
			.then((data) => {
				authDispatch({
					type: 'SET_AUTH',
					payload: {
						user: {
							email: data.email,
							firstname: data.firstname,
							lastname: data.lastname,
							profile_pic: data.profile_pic,
						},
						iam: data.iam,
						authenticated: true,
					},
				})

				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							session_id: data.session,
							account_type: data.session_type,
							wealth: data.steps.wealth,
							investment: data.steps.investment,
							kyc: data.steps.kyc,
							video: data.steps.video,
						},
					},
				})

				setReady(true)
			})
			.catch((err) => {
				authDispatch({
					type: 'SET_AUTH',
					payload: {
						user: {
							email: '',
							firstname: '',
							lastname: '',
							profile_pic: '',
						},
						iam: [],
						authenticated: false,
					},
				})
				setReady(true)
			})
		return () => { }
	}, [])

	return ready ? props.children : null
}

function App() {
	return (
		<AuthContextProvider>
			<LoginContextProvider>


				<AuthCore>
					<Router>
						<Switch>
							<Route exact path='/' component={Dashboard} />
							<Route exact path='/login' component={LoginPage} />
							<Route exact path='/signup' component={SignupPage} />
							<Route path='*' component={NotFoundPage} />
						</Switch>
					</Router>
					<ToastContainer
						position='top-right'
						autoClose={4000}
						hideProgressBar={false}
						newestOnTop
						closeOnClick={false}
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
					/>
				</AuthCore>
			</LoginContextProvider>
		</AuthContextProvider>
	)
}

export default App
